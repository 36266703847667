import { FormEvent, useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { listarCadastros } from '../../services/cadastros'
import { cadastrosRespostaPayload } from '../../interfaces/cadastros'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import ModalCadstro from '../ModalCadastros'
import FiltrosDefault from '../FiltrosDefault'
import ModalAlerta from '../ModalAlerta'
import DivSituacao from '../DivSituacao'
import Perfil from '../../middleware/perfil'
import LoagindBarra from '../LoadingBarra'
import LoadingCirculo from '../LoadingCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import erroImg from '../../assets/img/erroFechar.png'
import '../../styles/paginaListaDados.css'
import './cadastros.css'

function Cadastros () {

    const location = useLocation()
    const navigate = useNavigate()

    const { atualizarInformacoes, modal, modalExibir } = useContext(ModalEstadoContext)

    const [cadastros, setCadastros] = useState<cadastrosRespostaPayload[]>([])
    const [cadastroSelecionado, setCadastroSelecionado] = useState<cadastrosRespostaPayload>()
    const [maisCadastros, setMaisCadastros] = useState(false)
    const [pagina, setPagina] = useState(2)
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('ativo')
    const [processando, setProcessando] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [erroListarCadastros, setErroListarCadastros] = useState(false)
    

    const parans = new URLSearchParams(location.search)

    const tipo = parans.get('tipo')

    const { pathname } = location

    const ocultarModal = () => {

        return setErroListarCadastros(false)
    }

    const exibirModalNovoCadastro = () => {

        return modalExibir ('cadastro')
    }

    const exibirModalDadosCadastros = (cadastro: cadastrosRespostaPayload, idCadastro: string) => {

        navigate(`${pathname}?tipo=${tipo}&idCadastro=${idCadastro}`)
        modalExibir('cadastro')

        return setCadastroSelecionado(cadastro)
    }

    const scrollInfinito = (e: any) => {

        const { scrollTop, clientHeight, scrollHeight } = e.target;

        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight)*100)

        if (posicaoScroll >= 95) {

            setPagina(prevtState => prevtState + 1)

            const buscarMaisCadastros = async () => {

                setMaisCadastros(true)

                if(!tipo) {
                    return
                }

                const params = {
                    tipo,
                    pagina,
                    termo,
                    situacao
                }

                try {

                    const resposta = await listarCadastros(params)

                    if (resposta.status === 200) {
                        setCadastros(prevDate => [...prevDate, ...resposta.data])
                        return setMaisCadastros(false)
                    }
            
                } catch (error) {
                    setMaisCadastros(false)
                    setErroListarCadastros(true)

                    return setTimeout(() => {
                        setErroListarCadastros(false)
                    }, 2000)
                }
            }

            buscarMaisCadastros()
        }
    }

    const pesquisarCadastro = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessando(true)

        if (tipo === undefined || tipo === null || pagina === null){
            return
        }

        try {

            const params = {
                tipo,
                termo,
                pagina: '1',
                situacao
            }

            const resposta = await listarCadastros(params)

            if (resposta.data.length === 0) {
                setCadastros(resposta.data)
                setProcessando(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setCadastros(resposta.data)
                setProcessando(false)

                return
            }
            
        } catch (error) {

            setProcessando(false)
            return setErroListarCadastros(true)
            
        }
    }

    useEffect (() => {

        if(!modal.nome) setCadastroSelecionado(undefined)

    }, [modal])

    useEffect(() => {

        const cadastros = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            if(!tipo) {
                return
            }

            try {

                const params = {
                    tipo,
                    termo,
                    pagina: '1',
                    situacao
                }

                const resposta = await listarCadastros(params)

                if (resposta.data.length === 0) {
                    setCadastros(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {
                    setSituacao('ativo')
                    setCadastros(resposta.data)
                    setProcessando (false)

                    return
                }
                
            } catch (error) {

                setProcessando(false)
                return setErroListarCadastros(true)
                
            }
        }

        cadastros ()

    }, [tipo, atualizarInformacoes])


    return (
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
            <div id='paginaContainer'>
                {modal.nome === 'cadastro' && <ModalCadstro cadastro={cadastroSelecionado}/>}
                {processando && <LoagindBarra />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                {erroListarCadastros && <ModalAlerta texto='Ocorreu um erro ao listar os cadsatros, favor tente novamente!' icone={erroImg} btnTitulo='Tentar Novamente' cor='continuarErro' acao={ocultarModal} />}
                <FiltrosDefault termo={termo} situacao={situacao} tipo={tipo} placeholder='Pesquise pelo nome fantasia ou razão social' onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={pesquisarCadastro} acaoBtn={exibirModalNovoCadastro} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'ativo'}, {nome: 'Inativos', valor: 'inativo'}]} genero='masculino' isLoading={processando}/>

                <section id='fundoContainerPagina' onScroll={scrollInfinito}>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Nome Fantasia</th>
                                <th id='cadastroRazao' align='left'>Razão Social</th>
                                <th id='cidadeListaCadastro' align='left'>Cidade</th>
                                <th id='estadoCadastro' align='left'>Estado</th>
                                <th align='center'>Situação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cadastros.map((cadastro) => (
                                <tr key={cadastro.id} onClick={() => exibirModalDadosCadastros(cadastro, cadastro.id)}>
                                    <td id='primeiraColuna'>{cadastro.nomeFantasia}</td>
                                    <td id='cadastroNome'>
                                        <span>{cadastro.razaoSocial}</span>
                                        <span><b>CNPJ/CPF: </b>{cadastro.cnpjCpf}</span>
                                        <span>Responsável: <b>{cadastro.responsavelCadastro?.nomeFantasia}</b></span>
                                    </td>
                                    <td id='cidadeListaCadastro'>{cadastro.cidade}</td>
                                    <td id='estadoCadastro' align='left'>{cadastro.estado === null ? '' : cadastro.estado.charAt(0).toUpperCase()}{cadastro.estado === null ? '' : cadastro.estado.substring(1)}</td>
                                    <td id='ladoDireitoContent' align='center'>
                                        <DivSituacao texto={`${cadastro.situacao.charAt(0).toUpperCase()}${cadastro.situacao.substring(1)}`} cor={cadastro.situacao}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {maisCadastros && <div id='buscarMaisDados'>
                        <LoadingCirculo />
                    </div>}
                </section>
            </div>
        </Perfil>
    )
}

export default Cadastros