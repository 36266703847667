import { FormEvent, useEffect, useState } from 'react'
import { candidatoListaResposta } from '../../interfaces/BancoTalentos'
import { listarCandidatos } from '../../services/BancoTalentos'
import Perfil from '../../middleware/perfil'
import FiltrosDefault from '../FiltrosDefault'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import LoadingBarra from '../LoadingBarra'
import Notificacoes from '../Notificacoes'
import erroImg from '../../assets/img/erroFechar.png'
import avatarDefault from '../../assets/img/avatarDefault.png'
import DivSituacao from '../DivSituacao'
import Organizacao from '../../middleware/organizacao'

function BancoTalentos() {

    const [candidato, setCandidato] = useState('')
    const [situacaoPerfil, setSituacaoPerfil] = useState('')
    const [processando, setProcessando] = useState(false)
    const [processandoPesquisa, setProcessandoPesquisa] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [candidatos, setCandidatos] = useState<candidatoListaResposta[]>([])
    const [erro, setErro] = useState(false)

    const filtrarCandidato = async (e: FormEvent) => {

        e.preventDefault()
        setProcessandoPesquisa(true)
        
        const params = {
            nomeCompleto: candidato,
            situacaoCadastral: situacaoPerfil,
        }

        try {

            const resposta = await listarCandidatos(params)

            if(resposta.data.length === 0) {
                setCandidatos(resposta.data)
                setProcessandoPesquisa(false)
                return setNenhumResultado(true)
            }

            if(resposta.status === 200) {
                setCandidatos(resposta.data)
                setNenhumResultado(false)
                return setProcessandoPesquisa(false)
            }

        } catch (error) {
            setProcessandoPesquisa(false)
            setErro(true)
            
            return setTimeout(()=>{
                setErro(false)
            }, 2000)
        }

    }

    useEffect(() => {

        const procurarCandidatos = async () => {
            setNenhumResultado(false)
            setProcessando(true)
    
            const params = {
                nomeCompleto: "",
                situacaoCadastral: ""
            }
    
            try {
                const resposta = await listarCandidatos(params)
        
                if (resposta.data.length === 0) {
                    setCandidatos(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }
        
                if (resposta.status === 200) {
                    setCandidatos(resposta.data)
                    setNenhumResultado(false)
                    return setProcessando(false)
                }
            } catch (error) {

                setProcessando(false)
                setErro(true)
        
                return setTimeout(() => {
                    setErro(false)
                }, 2000)
            }
        }

        procurarCandidatos()

    }, [])

  return (
    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
        <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
            <div id='paginaContainer'>
                <FiltrosDefault termo={candidato} situacao={situacaoPerfil} placeholder='Pesquise pelo nome do candidato' tipo={""} onChangeTermo={(e) => setCandidato(e.target.value)} onChangeSituacao={(e) => setSituacaoPerfil(e.target.value)} onSubmit={(e)=>filtrarCandidato(e)} acaoBtn={() => { }} listaSituacao={[{ nome: 'Todos', valor: '' }, { nome: 'Completo', valor: 'Completo' }, { nome: 'Incompleto', valor: 'Incompleto' }]} genero='masculino' isLoading={processandoPesquisa} />
                {processando && <LoadingBarra />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação. Tente Novamente!' cor='erro' />}
                <section >
                <table id="tabelaListaContainer">
                    <thead>
                        <tr>
                            <th align='left'>Candidato</th>
                            <th align='left'>E-mail</th>
                            <th align='left'>Telefone</th>
                            <th align='left'>Cidade</th>
                            <th>Estado</th>
                            <th>Situação</th>
                        </tr>
                    </thead>
                    <tbody>
                    {candidatos.map((candidato) => (
                        <tr key={candidato.id}>
                            <td className='primeiraColuna'>
                                <div className='avatarContent'>
                                    <img className='avatar' src={candidato.avatar ? candidato.avatar : avatarDefault} alt=''/>
                                    <p>{candidato.nomeCompleto}</p>
                                </div>
                            </td>
                            <td align='left'>{candidato.email}</td>
                            <td align='left'>{candidato.telefone}</td>
                            <td>{candidato.cidade}</td>
                            <td>{candidato.estado}</td>
                            <td align='center' className='ladoDireitoContent'>
                                <DivSituacao texto={candidato.situacaoCadastral} cor={candidato.situacaoCadastral.toLocaleLowerCase()} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </section>
            </div>
        </Perfil>
    </Organizacao>
  )
}

export default BancoTalentos